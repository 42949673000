// Component to allow event sounds to be heard even when not on the Connect View

import {Alert} from '@mui/material';
import {Howl} from 'howler';
import {uniq} from 'lodash';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useConfiguration, useConfigurations} from '../../hooks/configuration';
import {useAppSelector} from '../../hooks/redux';
import {useRefreshInterval} from '../../hooks/refreshInterval';
import {EventSummaryResponse} from '../../interfaces/Event';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {getMsFromS} from '../../utils/datetime';
import {CONNECT_EVENT_SOUNDS} from '../../utils/events';
import {SOUNDS} from '../../utils/sounds';
import {isPresent} from '../../utils/type-guards';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';

export const EventSummarySounds = () => {
  const [fetchedEventSummaryErrors, setFetchedEventSummaryErrors] = useState<
    string[]
  >([]);

  const reduxDispatch = useDispatch();
  const company = useAppSelector(({assets}) => assets.company);
  const configurations = useConfigurations();
  const playedEventIds = useAppSelector(({app}) => app.playedEventIds);

  let dateParam = new Date();
  const offset = dateParam.getTimezoneOffset();
  dateParam = new Date(dateParam.getTime() - offset * 60 * 1000);
  const dateString = dateParam.toISOString().substring(0, 10);

  const fetchEventSummary = useCallback(async () => {
    setFetchedEventSummaryErrors([]);
    try {
      const params = {
        date_start: dateString,
        date_end: dateString,
      };
      const resp = await API.get<EventSummaryResponse>(
        `${apiBaseUrl}/event/summary`,
        {params}
      );
      if (soundFiles.length === 0) {
        setSoundFiles(getSoundFilesFromEventSummary(resp.data));
      }
    } catch (error: any) {
      // todo handle error
    }
  }, [configurations, playedEventIds]);

  const eventSummaryRefresh = useConfiguration(
    'commtrac',
    'commtrac_sound_interval'
  );
  const eventSummaryRefreshInterval = eventSummaryRefresh?.value
    ? getMsFromS(+eventSummaryRefresh?.value)
    : null;
  useRefreshInterval(fetchEventSummary, eventSummaryRefreshInterval);

  useEffect(() => {
    fetchEventSummary();
  }, []);

  const newEventSoundConfiguration = useConfiguration(
    'commtrac',
    'sound_new_event'
  );

  const getSoundFilesFromEventSummary = (
    eventSummary: EventSummaryResponse
  ) => {
    const eventTypes = uniq(
      company?.ams_enabled
        ? eventSummary.connect.sound
            .map((i) => i.type)
            .concat(eventSummary.ams.sound.map((i) => i.type))
        : eventSummary.connect.sound.map((i) => i.type)
    );

    const soundNames = eventTypes
      .map((i) => configurations[CONNECT_EVENT_SOUNDS[i] ?? '']?.value)
      .filter(isPresent);

    const newEvents = eventSummary.connect.sound.filter(
      (i) => !playedEventIds.includes(i.id)
    );
    if (newEvents.length) {
      soundNames.unshift(newEventSoundConfiguration?.value ?? '');
      reduxActions.app.setApp(reduxDispatch, {
        playedEventIds: [...playedEventIds, ...newEvents.map((i) => i.id)],
      });
    }
    return uniq(soundNames.map((i) => SOUNDS[i]).filter(isPresent));
  };

  const {enqueueSnackbar} = useSnackbar();
  const muteSounds = useAppSelector(reduxSelectors.app.getSoundMuteMode);
  const [soundFiles, setSoundFiles] = useState<any[]>([]);
  //const isSoundFilesEmpty = soundFiles.length === 0;
  const sound = useMemo(() => {
    const src = soundFiles[0];
    return src ? new Howl({src}) : null;
  }, [soundFiles]);

  useEffect(() => {
    if (sound) {
      console.log('new sound');
      if (muteSounds) {
        const message =
          'New event received, please enable sounds to miss nothing.';
        enqueueSnackbar(message, {
          variant: 'warning',
          action: CloseSnackbarAction,
        });
        return;
      }

      if (sound.state() === 'loaded') {
        sound.play();
      } else {
        sound.once('load', () => sound.play());
      }
      sound.once('end', () => {
        setSoundFiles(soundFiles.slice(1));
      });

      return () => {
        sound.unload();
      };
    }
  }, [sound]);

  return (
    <>
      {fetchedEventSummaryErrors.map((error, idx) => (
        <Alert
          key={`error-es-${idx}`}
          severity="error"
          onClose={fetchEventSummary}
        >
          {error}
        </Alert>
      ))}
    </>
  );
};
