import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  TextField,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CommunicationNode} from '../../interfaces/CommunicationNodeV2';
import AccessControl from '../common/AccessControl';
import CommunicationNodeTypeSelect from '../selectors/CommunicationNodeTypeSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import CommunicationNodeItemActionsButton from './CommunicationNodeItemActionsButton';
import CommunicationNodeItemHistoryButton from './CommunicationNodeItemHistoryButton';

interface Props {
  pk: number;
  item?: CommunicationNode;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onSubmitted?: () => void;
  onFetched?: (item: CommunicationNode) => void;
  onOpenHistory?: (externalId: number, type: 'cn' | 'commtracNodeByCn') => void;
}

const tabs = [
  {value: 'general', label: 'General'},
  {value: 'connection', label: 'Connection'},
  {value: 'state', label: 'State'},
];

const CommunicationNodeItemView = ({
  pk,
  item,
  prefetch,
  onCancel,
  onEdit,
  onAck,
  onPurged,
  onFetched,
  onSubmitted,
  onOpenHistory,
}: Props) => {
  const [activeTab, setActiveTab] = useState('general');

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<CommunicationNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<CommunicationNode>(
        `${apiBaseUrl}/node/${pk}/communication-node`
      );
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.name ?? ''}
              label="Name"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl
              //       permissions={['patch::/node/:id/communication-node']}
              //     >
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <CommunicationNodeTypeSelect
              value={fetchedData?.node_type}
              label="Node Type"
              size="small"
              disabled
              fullWidth
            />
            <StatusSelect
              value={fetchedData?.status}
              fullWidth
              label="Status"
              size="small"
              disabled
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl
              //       permissions={['patch::/node/:id/communication-node']}
              //     >
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <TextField
              value={fetchedData?.external_id ?? ''}
              label="Network ID"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl
              //       permissions={['patch::/node/:id/communication-node']}
              //     >
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <ZoneSelect
              value={fetchedData?.zone_id}
              label="Section"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl
              //       permissions={['patch::/node/:id/communication-node']}
              //     >
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <TextField
              value={fetchedData?.pos_lat ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl
              //       permissions={['patch::/node/:id/communication-node']}
              //     >
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <TextField
              value={fetchedData?.pos_long ?? ''}
              label="Longitude"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl
              //       permissions={['patch::/node/:id/communication-node']}
              //     >
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <TextField
              value={fetchedData?.type_id ?? ''}
              label="Type ID"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.date_activated ?? ''}
              label="Activated TimeStamp"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.date_deactivated ?? ''}
              label="DeActivated TimeStamp"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.report_frequency ?? ''}
              label="Report Frequency (s)"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.last_update ?? ''}
              label="Timestamp"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="connection" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.external_id ?? ''}
              label="Network ID"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="state" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.battery_voltage ?? ''}
              label="Battery"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.heartbeat_count ?? ''}
              label="Heartbeat"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.routing_root_address ?? ''}
              label="Routing Root Address"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.timing_root_address ?? ''}
              label="Timing Root Address"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.routing_parent_rssi ?? '0'}
              label="Routing Parent RSSI"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.timing_parent_rssi ?? '0'}
              label="Timing Parent RSSI"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.lost_routing_parents ?? ''}
              label="Lost Routing Parents"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.lost_timing_parents ?? ''}
              label="Lost Timing Parents"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
      </TabContext>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="start" gap={0.5}>
          {onOpenHistory && fetchedData?.external_id ? (
            <CommunicationNodeItemHistoryButton
              onOpen={(type) => {
                if (onOpenHistory && fetchedData?.external_id) {
                  onOpenHistory(fetchedData.external_id, type);
                }
              }}
            >
              History
            </CommunicationNodeItemHistoryButton>
          ) : null}
          {fetchedData ? (
            <>
              {onAck && fetchedData?.ack !== '1' ? (
                <AccessControl
                  permissions={[
                    'patch::/node/:id/communication-node/acknowledge',
                  ]}
                >
                  <Button onClick={() => onAck()}>Acknowledge</Button>
                </AccessControl>
              ) : null}
              <AccessControl
                permissions={['patch::/node/:id/communication-node']}
              >
                <CommunicationNodeItemActionsButton
                  item={fetchedData}
                  component={IconButton}
                  componentProps={{size: 'small', color: 'primary'}}
                  onSubmitted={() => {
                    fetchData();
                    onSubmitted?.();
                  }}
                  onPurged={onPurged}
                >
                  <MoreHorizIcon />
                </CommunicationNodeItemActionsButton>
              </AccessControl>
            </>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <AccessControl
              permissions={['patch::/node/:id/communication-node']}
            >
              <Button onClick={() => onEdit()}>Edit CN</Button>
            </AccessControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CommunicationNodeItemView;
