import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import BarChartIcon from '@mui/icons-material/BarChart';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BuildIcon from '@mui/icons-material/Build';
import Crop54Icon from '@mui/icons-material/Crop54';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {Box, Button, Checkbox, IconButton, Tooltip} from '@mui/material';
import update from 'immutability-helper';
import _ from 'lodash';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import {
  COMMUNICATION_NODE_TYPES,
  WIFI_POINT_TYPES,
} from '../../../constants/node';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {Node, NodeListResponse} from '../../../interfaces/Node';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {eventIconsLegacy} from '../../../utils/event-icons';
import {makeIconSmall} from '../../../utils/icons';
import {getBatteryPercentage, getNodeKey} from '../../../utils/nodes';
import {isPresent} from '../../../utils/type-guards';
import AccessControl from '../../common/AccessControl';
import {BatteryIcon} from '../../common/BatteryIcon';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import CommunicationNodeBroadcastReportFrequencyButton from '../../communication-node/CommunicationNodeBroadcastReportFrequencyButton';
import EventIcon from '../../EventIcon';
import NodeItemsEditButton from '../../nodes/buttons/NodeItemsEditButton';
import NodeItemsPurgeButton from '../../nodes/buttons/NodeItemsPurgeButton';
import {NodesReportData} from '../ConnectView/NodesReport';

interface CnWifiNodesGridProps {
  data?: NodeListResponse;
  config: NodesReportData;
  loading?: boolean;
  onChange?: (value: NodesReportData) => void;
  onOpenHistory?: (id: number | string, type: 'networkDiagnostics') => void;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
}

export const CnWifiNodesGrid = forwardRef(
  (props: CnWifiNodesGridProps, ref) => {
    const zones = useAppSelector(({assets}) => assets.zones);

    const dataGridRef = useRef<DataGridRef>(null);
    useImperativeHandle(ref, () => ({
      printTable: () => {
        dataGridRef.current?.printTable();
      },
    }));

    const config = useMemo<NodesReportData>(
      () => ({
        ...props.config,
      }),
      [props.config]
    );

    /*************/
    /* data grid */
    /*************/
    const permissions = useAppSelector(({app}) => app.me?.permissions);
    const rows = props.data?.items || [];
    const columns: DataGridColumn<Node>[] = [
      {
        field: 'select',
        type: 'select',
        hideable: false,
        renderHeader: () => (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Checkbox
              color="primary"
              disabled={rows.length === 0}
              checked={selectedItems.length > 0 && selectedAll}
              indeterminate={selectedItems.length > 0 && !selectedAll}
              onChange={() => toggleSelectAllItems()}
            />
          </Box>
        ),
        renderCell: ({row}) => (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Checkbox
              color="primary"
              checked={selectedItems.includes(getNodeKey(row))}
              onChange={() => toggleSelectItem(getNodeKey(row))}
              size="small"
            />
          </Box>
        ),
      },
      {
        field: 'actions',
        renderHeader: () => (
          <Tooltip title="More">
            <MoreHorizIcon />
          </Tooltip>
        ),
        type: 'actions',
        hideable: false,
        width: 40,
        hidden:
          !permissions?.includes('get::/node/:id/communication-node') &&
          !permissions?.includes('get::/node/:id/wifi-point'),
        sxHeader: {textAlign: 'center', p: 0.5},
        sxCell: {textAlign: 'center', p: 0.5},
        renderCell: ({row}) => {
          if (row.node_type === 'communication_node' && row.cn_id) {
            return row.ack === '0' ? (
              <AccessControl
                permissions={[
                  'patch::/node/:id/communication-node/acknowledge',
                ]}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (row.cn_id) {
                      props.onOpenItem?.(row.cn_id, 'cn', 'ack');
                    }
                  }}
                  size="small"
                >
                  <Tooltip title="Acknowledge">
                    <WarningAmberIcon color="warning" sx={{fontSize: 16}} />
                  </Tooltip>
                </IconButton>
              </AccessControl>
            ) : (
              <AccessControl
                permissions={['patch::/node/:id/communication-node']}
              >
                <NodeItemsEditButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'primary',
                    size: 'small',
                  }}
                  onOpenItem={props.onOpenItem}
                  onOpenHistory={props.onOpenHistory}
                >
                  <MoreHorizIcon sx={{fontSize: 16}} />
                </NodeItemsEditButton>
              </AccessControl>
            );
          } else if (row.node_type === 'wifi_point' && row.wifi_id) {
            return row.ack === '0' ? (
              <AccessControl
                permissions={['patch::/node/:id/wifi-point/acknowledge']}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (row.wifi_id) {
                      props.onOpenItem?.(row.wifi_id, 'wifi', 'ack');
                    }
                  }}
                  size="small"
                >
                  <Tooltip title="Acknowledge">
                    <WarningAmberIcon color="warning" sx={{fontSize: 16}} />
                  </Tooltip>
                </IconButton>
              </AccessControl>
            ) : (
              <AccessControl permissions={['patch::/node/:id/wifi-point']}>
                <NodeItemsEditButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'primary',
                    size: 'small',
                  }}
                  onOpenItem={props.onOpenItem}
                  onOpenHistory={props.onOpenHistory}
                >
                  <MoreHorizIcon sx={{fontSize: 16}} />
                </NodeItemsEditButton>
              </AccessControl>
            );
          }
        },
      },
      {
        field: 'cn_battery_voltage',
        headerName: 'Battery Voltage',
        sxHeader: {minWidth: 60},
        renderHeader: () => (
          <Tooltip title="Low Battery">
            <BatteryChargingFullIcon />
          </Tooltip>
        ),
        sortable: true,
        hideable: false,
        valueGetter: ({row}) => row.cn_battery_voltage ?? 0,
        renderCell: ({row}) => {
          if (
            row.node_type === 'communication_node' &&
            typeof row.cn_battery_voltage === 'number'
          ) {
            if (row.cn_battery_voltage <= 20) {
              return (
                <Tooltip title="External Power">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    <EventIcon
                      eventType="communicationNodes.externalPower"
                      sx={{fontSize: 16}}
                    />
                  </Box>
                </Tooltip>
              );
            }
            const value = getBatteryPercentage(row.cn_battery_voltage);
            return (
              <Tooltip title={`${value}%`}>
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  <BatteryIcon value={value} sx={{fontSize: 16}} />
                </Box>
              </Tooltip>
            );
          }
        },
      },
      {
        field: 'no_heartbeat',
        headerName: 'No heartbeat',
        sxHeader: {minWidth: 60},
        renderHeader: () => (
          <Tooltip title="No Heartbeat">
            <WifiIcon />
          </Tooltip>
        ),
        sortable: true,
        hideable: false,
        renderCell: ({row}) => {
          if (row.node_type === 'communication_node') {
            if (row.e_121) {
              return (
                <Tooltip title="Communication Node Many Heartbeat">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(eventIconsLegacy.nodes.cnManyHeartbeat)}
                  </Box>
                </Tooltip>
              );
            } else if (row.e_102) {
              return (
                <Tooltip title="Communication Node No Heartbeat">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(eventIconsLegacy.nodes.cnNoHeartbeat)}
                  </Box>
                </Tooltip>
              );
            } else if (row.e_104) {
              return (
                <Tooltip title="Communication Node Few Heartbeat">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(eventIconsLegacy.nodes.cnFewHeartbeat)}
                  </Box>
                </Tooltip>
              );
            }
          } else if (row.node_type === 'wifi_point') {
            if (row.wifi_connection_status === 'disconnected') {
              return (
                <Tooltip title="Disconnected">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(eventIconsLegacy.nodes.wifiDisconnected)}
                  </Box>
                </Tooltip>
              );
            }
          }
        },
      },
      {
        field: 'assigned_master',
        headerName: 'Assigned Master',
        sxHeader: {minWidth: 60},
        renderHeader: () => (
          <Tooltip title="Assigned Master">
            <MergeTypeIcon />
          </Tooltip>
        ),
        sortable: true,
        hideable: false,
        renderCell: ({row}) => {
          if (row.node_type === 'communication_node') {
            if (row.e_112) {
              return (
                <Tooltip title="Unregistered Network">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(
                      eventIconsLegacy.nodes.cnUnregisteredNetwork
                    )}
                  </Box>
                </Tooltip>
              );
            } else if (row.e_101) {
              return (
                <Tooltip title="Assigned Master">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(eventIconsLegacy.nodes.cnAssignedMaster)}
                  </Box>
                </Tooltip>
              );
            }
          }
        },
      },
      {
        field: 'msg_conf',
        headerName: 'Msg Conf',
        sxHeader: {minWidth: 60},
        renderHeader: () => (
          <Tooltip title="Msg Conf">
            <BuildIcon />
          </Tooltip>
        ),
        sortable: true,
        hideable: false,
        renderCell: ({row}) => {
          if (row.node_type === 'communication_node') {
            if (row.e_122) {
              return (
                <Tooltip title="Configuration Message Failed">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(
                      eventIconsLegacy.nodes.cnConfigurationMessageFailed
                    )}
                  </Box>
                </Tooltip>
              );
            } else if (row.configuration_message?.answer === 'NACK') {
              return (
                <Tooltip title="NACK">
                  <Box sx={{position: 'relative', left: 4, top: 4}}>
                    {makeIconSmall(
                      eventIconsLegacy.nodes.cnConfigurationMessageNack
                    )}
                  </Box>
                </Tooltip>
              );
            } else if (row.e_132) {
              return (
                <Tooltip title="ACK">
                  {makeIconSmall(
                    eventIconsLegacy.nodes.cnConfigurationMessageAck
                  )}
                </Tooltip>
              );
            }
          }
        },
      },
      {
        field: 'msg_version',
        headerName: 'Msg Version',
        sxHeader: {minWidth: 60},
        renderHeader: () => (
          <Tooltip title="Msg Version">
            <AllInclusiveIcon />
          </Tooltip>
        ),
        sortable: true,
        hideable: false,
        renderCell: ({row}) => {
          let title: string | null = null;
          if (row.e_110 && row.e_111) {
            title = 'PIC and CC1110';
          } else if (row.e_110) {
            title = 'PIC';
          } else if (row.e_111) {
            title = 'CC1110';
          }
          if (title) {
            return (
              <Tooltip title={`${title} 'Version Changed'`}>
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.cnVersionChanged)}
                </Box>
              </Tooltip>
            );
          }
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        valueGetter: ({row}) => row.name || '',
      },
      {
        field: 'node_type',
        headerName: 'Type',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return COMMUNICATION_NODE_TYPES.find(
              (i) => row.cn_node_type === `${i.id}`
            )?.name;
          } else if (row.node_type === 'wifi_point') {
            return WIFI_POINT_TYPES.find((i) => row.wifi_type === i.key)?.name;
          }
        },
      },
      {
        field: 'zone',
        headerName: 'Section',
        sortable: true,
        valueGetter: ({row}) => zones.find((i) => i.id === row.zone_id)?.name,
      },
      {
        field: 'cn_on_surface',
        headerName: 'Pos',
        sortable: true,
        valueGetter: ({row}) => row.cn_on_surface === '1',
        renderCell: ({row}) => {
          if (row.cn_on_surface === '1') {
            return (
              <Tooltip title="Surface">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  <BarChartIcon color="success" sx={{fontSize: 16}} />
                </Box>
              </Tooltip>
            );
          } else if (row.cn_on_surface === '0') {
            return (
              <Tooltip title="Underground">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  <Crop54Icon color="success" sx={{fontSize: 16}} />
                </Box>
              </Tooltip>
            );
          }
        },
      },
      {
        field: 'external_id',
        headerName: 'Network ID',
        sortable: true,
      },
      {
        field: 'wifi_ip',
        headerName: 'IP',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_ip;
          }
        },
      },
      {
        field: 'wifi_mac_switch',
        headerName: 'Switch',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_mac_switch;
          }
        },
      },
      {
        field: 'wifi_mac_radio_1',
        headerName: 'Radio 1',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_mac_radio_1;
          }
        },
      },
      {
        field: 'wifi_mac_radio_2',
        headerName: 'Radio 2',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_mac_radio_2;
          }
        },
      },
      {
        field: 'wifi_last_response',
        headerName: 'Last Response',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_last_response;
          }
        },
      },
      {
        field: 'wifi_last_latency',
        headerName: 'Latency',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_last_latency;
          }
        },
      },
      {
        field: 'longitude',
        headerName: 'Lon',
        sortable: true,
        valueGetter: ({row}) => {
          return row.longitude;
        },
      },
      {
        field: 'latitude',
        headerName: 'Lat',
        sortable: true,
        valueGetter: ({row}) => {
          return row.latitude;
        },
      },
      {
        field: 'cn_routing_root_address',
        headerName: 'Root Address',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_routing_root_address;
          }
        },
      },
      {
        field: 'cn_routing_parent_address',
        headerName: 'Parent Address',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_routing_parent_address;
          }
        },
      },
      {
        field: 'cn_routing_parent_rssi',
        headerName: 'Parent RSSI',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_routing_parent_rssi;
          }
        },
      },
      {
        field: 'cn_routing_number_of_hops',
        headerName: 'Hops to Root',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_routing_number_of_hops;
          }
        },
      },
      {
        field: 'cn_timing_root_address',
        headerName: 'Timing Root Address',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_timing_root_address;
          }
        },
      },
      {
        field: 'cn_timing_parent_address',
        headerName: 'Timing Parent Address',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_timing_parent_address;
          }
        },
      },
      {
        field: 'cn_timing_number_of_hops',
        headerName: 'Timing Hops to Root',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_timing_number_of_hops;
          }
        },
      },
      {
        field: 'cn_lost_routing_parents',
        headerName: 'Lost Routing Parents',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_lost_routing_parents;
          }
        },
      },
      {
        field: 'cn_lost_timing_parents',
        headerName: 'Lost Timing Parents',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_lost_timing_parents;
          }
        },
      },
      {
        field: 'cn_changed_routing_parents',
        headerName: 'Changed Routing Parents',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_changed_routing_parents;
          }
        },
      },
      {
        field: 'cn_changed_timing_parents',
        headerName: 'Changed Timing Parents',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_changed_timing_parents;
          }
        },
      },
      {
        field: 'cn_network_entry',
        headerName: 'Network Entry',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_network_entry;
          }
        },
      },
      {
        field: 'cn_network_entry_reason',
        headerName: 'Network Entry Reason',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_network_entry_reason;
          }
        },
      },
      {
        field: 'cn_idle_subframes',
        headerName: 'Idle Subframes',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_idle_subframes;
          }
        },
      },
      {
        field: 'cn_neighbor_table_full',
        headerName: 'Neighbor Table Full',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_neighbor_table_full;
          }
        },
      },
      {
        field: 'cn_neighbor_table_count',
        headerName: 'Neighbor Table Count',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_neighbor_table_count;
          }
        },
      },
      {
        field: 'cn_cc1110_version',
        headerName: 'CC1110 Version',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_cc1110_version;
          }
        },
      },
      {
        field: 'cn_pic_version',
        headerName: 'PIC Version',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_pic_version;
          }
        },
      },
      {
        field: 'cn_heartbeat_count',
        headerName: 'Heartbeat Count',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_heartbeat_count;
          }
        },
      },
      {
        field: 'cn_message_overflow_count',
        headerName: 'Message Overflow Count',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_message_overflow_count;
          }
        },
      },
      {
        field: 'cn_routing_above_threshold',
        headerName: 'Routing Above Threshold',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_routing_above_threshold;
          }
        },
      },
      {
        field: 'cn_timing_above_threshold',
        headerName: 'Timing Above Threshold',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_timing_above_threshold;
          }
        },
      },
      {field: 'cn_date_activated', headerName: 'Activated at', sortable: true},
      {
        field: 'cn_date_deactivated',
        headerName: 'Deactivated at',
        sortable: true,
      },
      {
        field: 'wifi_last_ping',
        headerName: 'Last Ping',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'wifi_point') {
            return row.wifi_last_ping;
          }
        },
      },
      {
        field: 'cn_last_update',
        headerName: 'Timestamp',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.node_type === 'communication_node') {
            return row.cn_last_update;
          }
        },
      },
    ];

    const shownFields = useMemo(() => {
      if (_.isEqual(config.params.node_type, ['communication_node'])) {
        return config.shownFields.cn;
      } else if (_.isEqual(config.params.node_type, ['wifi_point'])) {
        return config.shownFields.wifi;
      }
      return config.shownFields.all;
    }, [config]);

    useEffect(() => {
      props.onChange?.(
        update(config, {
          exportFields: {
            $set: columns
              .filter((col) => !!col.headerName && col.field !== 'actions')
              .map((col) => ({
                field: col.field,
                label: col.headerName,
                hidden: !shownFields?.includes(col.field),
              })),
          },
        })
      );
    }, [shownFields]);

    const handleChangeShownFields = (fields: string[]) => {
      if (_.isEqual(config.params.node_type, ['communication_node'])) {
        props.onChange?.(
          update(config, {
            shownFields: {
              cn: {$set: fields},
            },
          })
        );
      } else if (_.isEqual(config.params.node_type, ['wifi_point'])) {
        props.onChange?.(
          update(config, {
            shownFields: {
              wifi: {$set: fields},
            },
          })
        );
      } else {
        props.onChange?.(
          update(config, {
            shownFields: {
              all: {$set: fields},
            },
          })
        );
      }
    };

    /*******************/
    /* multiple select */
    /*******************/
    const selectedItems = config.selectedIds ?? [];

    const selectedRows = useMemo(
      () => rows.filter((i) => config.selectedIds?.includes(getNodeKey(i))),
      [rows, config.selectedIds]
    );

    const selectedAll = useMemo(
      () => rows.length === selectedRows.length,
      [rows, selectedRows]
    );

    const toggleSelectItem = (id: string) => {
      if (config.selectedIds?.includes(id)) {
        props.onChange?.(
          update(config, {
            selectedIds: {
              $set: config.selectedIds.filter((i) => i !== id),
            },
          })
        );
      } else {
        props.onChange?.(
          update(config, {
            selectedIds: {
              $set: [...(config.selectedIds ?? []), id],
            },
          })
        );
      }
    };

    const selectAll = () => {
      props.onChange?.(
        update(config, {
          selectedIds: {
            $set: rows?.map((i) => getNodeKey(i)) ?? [],
          },
        })
      );
    };

    const unselectAll = () => {
      props.onChange?.(
        update(config, {
          selectedIds: {
            $set: [],
          },
        })
      );
    };

    const toggleSelectAllItems = () => {
      if (selectedItems.length >= rows.length) {
        unselectAll();
      } else {
        selectAll();
      }
    };

    useEffect(() => {
      if (
        config.selectedIds &&
        config.selectedIds.length !== selectedRows.length
      ) {
        props.onChange?.(
          update(config, {
            selectedIds: {
              $set: selectedRows.map((i) => getNodeKey(i)),
            },
          })
        );
      }
    }, [config.selectedIds, selectedRows]);

    const prevSelectedAll = usePrevious(selectedAll);

    useEffect(() => {
      if (prevSelectedAll && !selectedAll) {
        selectAll();
      }
    }, [rows]);

    return (
      <Box height="100%">
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          size="small"
          pagination
          page={config.grid.page}
          pageSize={config.grid.pageSize}
          loading={props.loading}
          shownFields={shownFields}
          sxFooter={{
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
          }}
          footerStart={
            selectedItems?.length ? (
              <Box display="flex" alignItems="center" gap={3}>
                <Box
                  display="flex"
                  gap={0.5}
                  alignItems="center"
                  height="100%"
                  whiteSpace="nowrap"
                >
                  {selectedItems.length} selected
                </Box>

                <AccessControl permissions={['post::/purge']}>
                  <Box display="flex" height={40}>
                    <NodeItemsPurgeButton
                      cnIds={selectedRows
                        .filter((i) => i.cn_id)
                        .map((i) => i.cn_id)
                        .filter(isPresent)}
                      wifiIds={selectedRows
                        .filter((i) => i.wifi_id)
                        .map((i) => i.wifi_id)
                        .filter(isPresent)}
                      componentProps={{size: 'small', variant: 'outlined'}}
                      onDone={() => {}}
                    >
                      <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1}} />{' '}
                      Purge
                    </NodeItemsPurgeButton>
                  </Box>
                </AccessControl>

                <Box
                  display="flex"
                  alignItems="center"
                  height={70}
                  minWidth={490}
                  gap={2}
                  py="15px"
                >
                  <Button
                    onClick={() =>
                      props.onOpenHistory?.(0, 'networkDiagnostics')
                    }
                    variant="outlined"
                    size="small"
                    sx={{height: '100%'}}
                  >
                    Network Diagnostics
                  </Button>
                  <AccessControl
                    permissions={[
                      'post::/node/communication-node/broadcast_report_frequency',
                    ]}
                  >
                    <CommunicationNodeBroadcastReportFrequencyButton
                      componentProps={{
                        variant: 'outlined',
                        size: 'small',
                        sx: {height: '100%'},
                      }}
                    >
                      Broadcast Reporting Frequency
                    </CommunicationNodeBroadcastReportFrequencyButton>
                  </AccessControl>
                </Box>
              </Box>
            ) : null
          }
          onShownFieldsChange={handleChangeShownFields}
          onPageChange={(v) => {
            props.onChange?.(
              update(config, {
                grid: {
                  page: {
                    $set: v,
                  },
                },
              })
            );
          }}
          onPageSizeChange={(v) => {
            props.onChange?.(
              update(config, {
                grid: {
                  pageSize: {
                    $set: v,
                  },
                },
              })
            );
          }}
        />
      </Box>
    );
  }
);
